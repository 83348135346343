import {CustomLabels} from "../../../Common/customLabels";
import {WidgetsService} from "../../../Http/Widgets/WidgetsService";
import template from "./accounts-summary-item-hsa.html"
import templateNonHsa from "./accounts-summary-item-non-hsa.html"

(function ($, ko) {
    ko.components.register('accounts-summary-item-hsa', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new AccountsSummaryItem(params, widgetInfo.updateHeight, params.template);
            }
        }
    });

    ko.components.register('accounts-summary-item-non-hsa', {
        template: templateNonHsa,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new AccountsSummaryItem(params, widgetInfo.updateHeight, params.template);
            }
        }
    });

    function AccountsSummaryItem(params, updateHeight, templateInfo) {
        const self = this;
        $.cdh.extensions.profile.ProfileItemBase.call(self, updateHeight);
        $.extend(true, self, params.account);

        self.mobileButtonsVisible = ko.observable($.cdh.isMobile && self.AccountType !== $.cdh.enums.accountType.hsa && !self.FieldsAliases.Balance && !self.FieldsAliases.Spent);
        self.isTrustPlantId = ko.observable(false);
        self.showMobileButtons = function () {
            self.mobileButtonsVisible(!self.mobileButtonsVisible());
        };
        if (self.DataPartnerDetails) {
            self.isTrustPlantId(self.DataPartnerDetails.TrustPlanId !== "");
        }

        self.expanded = ko.observable(false);
        self.expandedHandler = function (data, event) {
            const newValue = !data.expanded();

            event.target.setAttribute('aria-expanded', newValue);
            data.expanded(newValue);
        };
        self.expandendKeyboardHandler = function (data, event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                const scrollDelay = 500;
                if (data.expanded()) {
                    setTimeout(function () {
                        $.cdh.helper.scrollPageToElement($('.benefit-block_mobile-chart'));
                    }, scrollDelay);
                    return true;
                }
            }
            return true;
        };


        self.currentYearDepositsVisible = !self.IsVeba;
        self.contributionsSummaryVisible = !self.IsVeba;
        self.LifetimeLimitPerEvent = self.LifetimeLimitPerEvent;
        self.LifetimeSpendingLimit = self.LifetimeSpendingLimit;
        self.LifetimeLimitOnNumberOfEvents = self.LifetimeLimitOnNumberOfEvents;
        self.rewardsUrl = ko.observable();
        self.widgetsService = new WidgetsService();
        self.widgetsService.getRewardsUrlAsync().then(msg => {
            self.rewardsUrl(msg.message);
        });
        self.hasInvestmentAccount = ko.pureComputed(() => $.cdh.user.impersonated ? self.InvestmentState === $.cdh.enums.investmentSolutionStates.activeDrive : self.InvestmentAccountId !== '' && self.InvestmentAccountId !== null);

        self.isInvestmentActive = ko.pureComputed(() => self.InvestmentState === $.cdh.enums.investmentSolutionStates.activeDrive ||
            self.InvestmentState === $.cdh.enums.investmentSolutionStates.inactiveDrive);

        self.investmentUrl = ko.observable();
        if (self.InvestmentState !== $.cdh.enums.investmentSolutionStates.none){
            if (self.isInvestmentActive()) {
                self.investmentUrl ("/Page/Investment/#/account/" + self.AccountId);
            }else {
                self.investmentUrl ("/Page/InvestmentsForAccount?accountId=" + self.AccountId);
            }
        }

        self.investmentfundListUrl = "/Page/Investment/#/account/" + self.AccountId + "?redirectUrl=fundList&backUrl=%2fPage%2fAccountsSummary";
        self.isInterestOptionActive = () => (self.HighYieldEligibility || self.HighYieldOptIn == true);

        if (self.AccountType === $.cdh.enums.accountType.hsa) {

            self.RequiredCipDocumentation = ko.observable(false);

            self.AvailableBalanceLabel = $.cdh.languageConstant.getConstant('AccountSummary.lblCashAvailableToSpend');
            self.PortfolioBalanceLabel = self.HsaAdvAccessibilityFlag ? $.cdh.languageConstant.getConstant('AccountSummary.lblHSAAdvance') : $.cdh.languageConstant.getConstant('AccountSummary.lblInvestmentBal');
            self.TotalLabel = $.cdh.languageConstant.getConstant('AccountSummary.lblTotalBal');

            //show electronic delivery proposal
            self.offerChangeStatementDelivery = ko.observable(false);
            self.offerChangeTaxDelivery = ko.observable(false);
            self.offerChangeFeeAmt = ko.observable();
            self.offerChangeDelivery = ko.pureComputed(function () {
                return self.offerChangeStatementDelivery() || self.offerChangeTaxDelivery();
            });

            self.changeDeliveryTaxParams = ko.pureComputed(function () {
                let urlParams = "accountId=" + self.AccountId;
                if (self.offerChangeTaxDelivery())
                    urlParams = urlParams + "&streamline=true";
                return urlParams;
            });

            self.changeDeliveryStatementParams = ko.pureComputed(function () {
                let urlParams = "accountId=" + self.AccountId;
                if (self.offerChangeStatementDelivery())
                    urlParams = urlParams + "&streamline=true";
                return urlParams;
            });

            self.template = new $.cdh.services.templateExtractor(templateInfo);

            self.offerChangeDeliveryText = ko.pureComputed(function () {
                let feeAmt = self.offerChangeFeeAmt();
                if (feeAmt > 0) {
                    if (self.offerChangeTaxDelivery() && self.offerChangeStatementDelivery())
                        return CustomLabels.documentDeliveryPreferences.goGreenAll(feeAmt);

                    if (!self.offerChangeTaxDelivery() && self.offerChangeStatementDelivery())
                        return CustomLabels.documentDeliveryPreferences.goGreenStatements(feeAmt);

                    if (self.offerChangeTaxDelivery() && !self.offerChangeStatementDelivery())
                        return CustomLabels.documentDeliveryPreferences.goGreenTaxForms(feeAmt);
                }

                return "";
            });

            self.hsa = new $.cdh.get.accounts.detailsHsa(self.AccountId);
            self.hsa.load().done(function (details) {
                self.offerChangeFeeAmt(details.StatementFeeAmt);

                if (details.TaxFormsDeliveryOption === $.cdh.enums.taxFormsPaperOrElectronic.paper && self.offerChangeFeeAmt() > 0)
                    self.offerChangeTaxDelivery(true);

                if (details.StatementsDeliveryOption === $.cdh.enums.statementDeliveryOption.electronicAndPaper && self.offerChangeFeeAmt() > 0)
                    self.offerChangeStatementDelivery(true);

                self.uploadDocumentQueuePopupModel = new $.cdh.models.UploadDocumentQueuePopupModel(self.FlexAcctId, updateHeight);
                self.RequiredCipDocumentation(details.RequiredCipDocumentation);

                updateHeight ();
            });

            if (self.BillPayEnabled) {
                self.addPaymentModelBuilder = function () {
                    return new $.cdh.models.PaymentsAddPopup(self.AccountId);
                };
            }

            self.planAutoPaymentPreferenceEnabled = ko.pureComputed(function () {
                return self.PlanAutoPaymentPreferenceEnabled;
            });

            self.planAutoReimbursementPreferenceEnabled = ko.pureComputed(function () {
                return self.PlanAutoReimbursementPreferenceEnabled;
            });

            self.showPreference = ko.pureComputed(function () {
                const autoPaymentPrefAvailable = self.planAutoPaymentPreferenceEnabled() && $.cdh.employee.allowAutoPaymentPreference();
                const autoReimbursementPrefAvailable = self.planAutoReimbursementPreferenceEnabled() && $.cdh.employee.allowAutoReimbursementPreference();

                return autoPaymentPrefAvailable || autoReimbursementPrefAvailable;
            });

            self.preferenceModelBuilder = function () {
                return new $.cdh.models.ClaimsAutoPaymentPrefs({ accountId: self.AccountId, options: 0 });
            };

        }

        else {

            self.accountIntervalType = ko.observable(false);
            self.IsMonthOrQuarter = ko.observable(false);
            self.extendedDetails = new $.cdh.get.accounts.detailsNonHsa(self.AccountId, self.Options);
            self.extendedDetails.state.subscribe(updateHeight);
            self.extendedDetails.load();

            self.PayrollHeader = ko.observable("Payroll Deposits YTD");
            self.currentInvervalPeriod = ko.observable(null);
            self.currentIntervalBalance = ko.observable(null);
            self.currentIntervalDeposits = ko.observable(null);
            self.currentIntervalDisbursed = ko.observable(null);
            self.currentIntervalRemaining = ko.observable(null);
            self.currentSpendingLimit = ko.observable(null);
            self.IsFutureYearPlan = (self.AccountPlanYear === $.cdh.enums.planYear.future);

            self.extendedIntervalDetails = new $.cdh.get.accounts.intervalTracker(self.AccountId);

            var currentMonth = String($.cdh.helper.getCurrentMonth('long') + ' ' + $.cdh.helper.getCurrentYear());
            var currentQuarter = $.cdh.helper.getCurrentQuarter() + '(' + $.cdh.helper.getCurrentQuarterDates() + ')';
            var monthIntervalVal = '1';
            var quarterIntervalVal = '3';
            
            self.IsMonthOrQuarter = (self.SpendingLimitPeriod == monthIntervalVal || self.SpendingLimitPeriod == quarterIntervalVal);
            if (self.IsMonthOrQuarter) {              
                self.extendedIntervalDetails.state.subscribe(updateHeight);

                self.extendedIntervalDetails.load().done(function (data) {
                    if (self.AccountPlanYear === $.cdh.enums.planYear.current || self.AccountPlanYear === $.cdh.enums.planYear.previous) {
                        if (data.length) {
                            var filteredData;
                            filteredData = data[data.length - 1];
                            filteredData = filteredData.IntervalType == monthIntervalVal || filteredData.IntervalType == quarterIntervalVal ? filteredData : [];

                            if (filteredData) {
                                self.currentInvervalPeriod(filteredData.IntervalPeriod);
                                self.currentIntervalBalance(filteredData.IntervalBalance);
                                self.currentIntervalDeposits(filteredData.IntervalDeposits);
                                self.currentIntervalDisbursed(filteredData.IntervalDisbursed);
                                self.currentIntervalRemaining(filteredData.IntervalRemaining);
                                self.currentSpendingLimit(filteredData.SpendingLimit);

                                if (filteredData.IntervalType == monthIntervalVal || String(filteredData.IntervalPeriod) === currentMonth) {
                                    self.PayrollHeader($.cdh.languageConstant.getConstant('AccountSummaryNonHSA.lblPayRollHeaderMonthly'));
                                }
                                else if (filteredData.IntervalType == quarterIntervalVal || (String(filteredData.IntervalPeriod) === currentQuarter)) {
                                    self.PayrollHeader($.cdh.languageConstant.getConstant('AccountSummaryNonHSA.lblPayRollHeaderQuarterly'));
                                }
                                self.accountIntervalType(true);
                            }
                        }
                    }
                    else if (self.AccountPlanYear === $.cdh.enums.planYear.future) {
                        self.currentInvervalPeriod(0);
                        self.currentIntervalBalance(0);
                        self.currentIntervalDeposits(0);
                        self.currentIntervalDisbursed(0);
                        self.currentIntervalRemaining(0);
                        self.currentSpendingLimit(0);
                        if (self.SpendingLimitPeriod == monthIntervalVal) {
                            self.PayrollHeader($.cdh.languageConstant.getConstant('AccountSummaryNonHSA.lblPayRollHeaderMonthly'));
                        }
                        else if (self.SpendingLimitPeriod == quarterIntervalVal) {
                            self.PayrollHeader($.cdh.languageConstant.getConstant('AccountSummaryNonHSA.lblPayRollHeaderQuarterly'));
                        }
                        self.accountIntervalType(true);
                    }
                });
            }
        }

        self.submitClaimBuilder = function () {
            let model = new $.cdh.models.SubmitClaimWidgetModel(updateHeight, updateHeight);
            model.forAccountId = self.AccountId;
            model.EligibilityDate = self.EligibilityDate;
            model.title = "Submit Claim";
            return model;
        };

        self.planDescAvailable = ko.observable($.cdh.helper.isValueSet(self.PlanUsageDescription));
        self.isPlanDescVisible = ko.observable(false);
        self.togglePlanDesc = function () {
            self.isPlanDescVisible(!self.isPlanDescVisible());
        };

        self.topBtnKeyDown = function (data, e) {
            if (e.keyCode === 32 || e.keyCode === 13) {
                e.target.click();
                return false;
            }
            return true;
        };

        self.sourceCarryoverAmountLabel = ko.observable(self.ActiveCarryoverSettings ? self.ActiveCarryoverSettings.AmountCustomLabel : null);
    }

})(jQuery, ko);