import template from "./account-activity-pay-now.html";
(function ($, ko) {
    ko.components.register('account-activity-pay-now', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityPayNow(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityPayNow(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.isMobile = params.isMobile || false;
        self.disable = model.Disable;
        self.detailsRequest = new $.cdh.get.repayment.details();
        self.accountActivityBalanceDueRequest = new $.cdh.get.accountActivity.getBalanceDue();
        self.balanceDue = ko.observable();
        self.directDeposit = ko.observable();
        self.showPayNow = params.showPayNow;
        self.transactionkey = ko.observable(model.TransactionKey);
        self.onlineRepaymentAllowed = ko.observable(false);
        self.avoidOverPaymentTips = ko.observable();
        self.isBankExist = ko.pureComputed(function () {
            return self.directDeposit();
        });
        self.isBankActive = ko.pureComputed(function () {
            return self.isBankExist() && self.directDeposit().validationStatus === $.cdh.enums.bankAccountValidationStatus.active;
        });
        const urlParams = {
            page: $.cdh.helper.urlParam('page')
        }
        self.force = urlParams.page === 'PayNow';
        self.detailsRequest.load().done(data => {
            self.onlineRepaymentAllowed(data.OnlineRepaymentAllowed);
            self.balanceDue(data.BalanceDue);

            if (data.OnlineRepaymentAllowed) {
                self.avoidOverPaymentTips(data.AvoidOverPaymentTips);

                if (data.DirectDeposit) {
                    self.directDeposit({
                        bank: data.DirectDeposit.Bank,
                        bankAccountNumber: data.DirectDeposit.BankAccountNumber,
                        validationStatus: data.DirectDeposit.ValidationStatus
                    });
                }
            }
        });
    }

})(jQuery, ko);
