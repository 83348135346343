import template from "./accounts-summary-mini.html"

(function ($, ko) {
    ko.components.register('accounts-summary-mini', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new AccountsSummaryMiniModel(widgetInfo.updateHeight, params.template);
            }
        }
    });

    var accountsFilter = function () {
        const self = this;

        self.previous = $.cdh.enums.planYear.previous;
        self.current = $.cdh.enums.planYear.current;
        self.future = $.cdh.enums.planYear.future;

        self.selectedPlanYear = ko.observable();
    };

    function AccountsSummaryMiniModel(updateHeight, templateInfo) {
        const self = this;
        $.cdh.extensions.profile.ProfileItemBase.call(self, updateHeight);
        
        self.templateExtractor = new $.cdh.services.templateExtractor(templateInfo);

        self.accountsRequest = $.cdh.services.accounts().initHttpRequest(function (json) {
            json.forEach(function (account) {
                account.isHsa = account.AccountType === $.cdh.enums.accountType.hsa;
            });
            return json;
        });

        self.accounts = ko.observableArray();

        self.isLoading = ko.pureComputed(function () {
            return self.accountsRequest.isLoading() && self.profile() !== null;
        });

        self.expanded = ko.observable(false);
        self.expanded.subscribe(updateHeight);
        self.expandedHandler = function (data, event) {
            const newValue = !data.expanded();

            event.target.setAttribute('aria-expanded', newValue);
            data.expanded(newValue);
        };
     
        self.expandenKeyboardHandler = function (data, event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                const scrollDelay = 500;
                if (data.expanded()) {
                    setTimeout(function() {
                        $.cdh.helper.scrollPageToElement($('.b-dashboard-account-expand'));
                    }, scrollDelay);
                    return true;
                }

                const firstItem = event.target.parentNode.querySelector('.b-dashboard-accounts-cover-link');
                if (!firstItem) {
                    return true;
                }

                setTimeout(function() {
                    $.cdh.helper.scrollPageToElement($('.unit.accounts-block'), function() {
                        firstItem.focus();
                    });
                }, scrollDelay);
            }

            return true;
        };
        self.timer = null;
        self.filter = new accountsFilter();
        self.filter.selectedPlanYear.subscribe(function (value) {
            clearTimeout(self.timer);
            self.timer = setTimeout(function () {
                const selectedFilter = document.querySelector('.ch-item-container input:checked');
                self.accountsRequest.load({
                    planYear: selectedFilter.value == value ? value : selectedFilter.value
                }).done(function (data) {
                    data.sort(function (a, b) {
                        if (a.ProductPartnerAcctStatus != 1 && a.ProductPartnerAcctStatus != 4 && b.ProductPartnerAcctStatus != 1 && b.ProductPartnerAcctStatus != 4) {
                            if (a.Priority < b.Priority)
                                return -1;
                            else
                                return 1;
                        }
                        else if (a.ProductPartnerAcctStatus == 1 || a.ProductPartnerAcctStatus == 4 && (b.ProductPartnerAcctStatus != 1 && b.ProductPartnerAcctStatus != 4)) {
                            return 1;
                        }
                        else if (b.ProductPartnerAcctStatus == 1 || b.ProductPartnerAcctStatus == 4 && (a.ProductPartnerAcctStatus != 1 && a.ProductPartnerAcctStatus != 4)) {
                            return -1;
                        }
                        else if (a.status == b.status) {
                            return a.priority - b.priority;
                        }
                        else {
                            if (a.ProductPartnerAcctStatus < b.ProductPartnerAcctStatus)
                                return -1;
                            else
                                return 1;
                        }
                    });

                    data.forEach(account => account.productPartnerStatusValue = getproductPartnerStatus(account.ProductPartnerAcctStatus));

                    self.accounts(data);
                    updateHeight();
                    if (selectedFilter) {
                        if (!$.cdh.isMobile) {
                            selectedFilter.focus();
                        }
                    }
                })
            }, 1000);
        });

        self.filter.selectedPlanYear(self.filter.current);

        function getproductPartnerStatus(productstatus) {
            switch (productstatus) {
                case 0: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Active");
                case 3: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Dormant");
                case 4: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Closed");
                case 5:
                case 6:
                case 7:
                case 10: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Frozen");
                case 11: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Escheated");
                default: return '';
            }
        }
    }
})(jQuery, ko);