import template from './site-map.html';
import {ComponentBase} from "../Common/componentBase";
import {RegisterDefaultComponent} from "../Common/componentsHelpers";
import { SiteMapService } from "../Http/SiteMap/SiteMapService";
import { AccountsService } from "../Http/Accounts/AccountsService";

export class SiteMapViewModel extends ComponentBase {
    constructor(params) {
        super(params);

        this.urls = ko.observableArray();
        this.siteMapService = new SiteMapService();
        this.accountService = new AccountsService();
        this.userIsAuthenticated = $.cdh.user.authenticated;
        this.isTaxFormConfigured = ko.observable(false);
        this.init();
    }

    async init() {
        let result = await this.siteMapService.getUrlsAsync();
        // Need to check whether TaxForms URL is configured or not in CMS
        const taxFormsUrlExist = result.data().filter(item => Array.isArray(item.Children)).map(item => {
            return {
                ...item,
                Children: item.Children.filter(child => child.Url && child.Url.includes('accoutId=0'))
            };
        }).filter(item => item.Children.length > 0).length > 0;

        this.isTaxFormConfigured(taxFormsUrlExist);

        if (this.isTaxFormConfigured() && this.userIsAuthenticated && !$.cdh.user.isAnonymousEnrollmentUser) {
            this.accountService.getListHsaAsync($.cdh.enums.planYear.all).then((accounts) => {
                if (accounts && accounts.length > 0 && accounts.filter(ac => ac.IsHsa == true && ac.IsVeba == false).length > 0) {
                    let sortedHSAAccountList = accounts.filter(ac => ac.IsHsa == true && ac.IsVeba == false).sort((a, b) => a.Priority - b.Priority);
                    let urlsArray = result.data();
                    urlsArray.forEach(
                        item => {
                            if (item.Children && Array.isArray(item.Children)) {
                                item.Children.forEach(child => {
                                    if (child.Url && child.Url.includes('accoutId=0')) {
                                        child.Url = child.Url.replace('accoutId=0', 'accountId=' + sortedHSAAccountList[0].AccountId);
                                    }
                                });
                            }
                        }
                    );
                    this.urls(urlsArray);
                }
                else {
                    let exceptTaxfomsArray = result.data().map(
                        item => {
                            if (item.Children && Array.isArray(item.Children)) {
                                item.Children = item.Children.filter(child => !child.Url || !child.Url.includes('accoutId=0'));
                            }
                            return item;
                        });
                    this.urls(exceptTaxfomsArray);
                }
            });
        }
        else {
            this.urls(result.data());
        }
    }
}

RegisterDefaultComponent('site-map', template, SiteMapViewModel);