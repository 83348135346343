import template from "./tax-forms-list.html"

(function ($, ko) {
	ko.components.register('tax-forms-list', {
		template: template,
		viewModel: {
			createViewModel: function (params, componentInfo) {
				return new TaxFormsPageModel(params, updateContainerHeight(componentInfo.element));
			}
		}
	});

    function TaxFormsPageModel(params, updateHeight) {
	    this.updateHeight = updateHeight;
        this.accountId = parseInt($.cdh.helper.urlParam('accountId'));
        this.streamline = $.cdh.helper.urlParam('streamline');
        this.title = $.cdh.helper.titlePage();

        this.historical = ko.observable(false);
        this.currentStepModel = ko.observable(null);

        this.currentStepModel.subscribe(function (val) {
	        $.cdh.helper.titleStepModelHandler(val, this.title);
	        setTimeout(updateHeight, 600);
        }, this);

	    this.itemsRequest = new $.cdh.get.taxforms.list(this.accountId);
        this.itemsRequest.load();

        this.itemsReassociatedRequest = new $.cdh.get.taxforms.listReassociated();

        this.gridItems = ko.pureComputed(function() {
	        if (this.historical() === true)
                return this.itemsReassociatedRequest.data();
	        return this.itemsRequest.data();
        }, this);

        this.deliveryOption = ko.observable();
        this.pinAlreadyValidated = ko.observable(false);
        this.deliveryOptionRequest = new $.cdh.get.taxforms.getDeliveryOption(this.accountId);
        this.deliveryOptionRequest.load();
        this.deliveryOptionRequest.data.subscribe(function(val) {
	        this.deliveryOption(val);
        }, this);

        this.accountsRequest = $.cdh.accounts.initAccounts();
        this.accountsReassociatedRequest = new $.cdh.get.accounts.listReassociated();
        this.accountsReassociatedRequest.load({ id: this.accountId });

        this.pdfPinRequest = new $.cdh.get.taxforms.pdfpin(this.accountId);
        this.pdfPinRequest.load();

	    this.aggregatedStatus = new $.cdh.models.aggregatedLoadStatus([
            this.accountsRequest,
            this.accountsReassociatedRequest,
            this.itemsRequest,
            this.deliveryOptionRequest,
            this.pdfPinRequest
        ]);

        this.anyReassociatedAccount = ko.pureComputed(function () {
	        if (!this.accountsReassociatedRequest.hasData)
                return false;

	        return this.accountsReassociatedRequest.data().Accounts.length > 0;
        }, this);

        this.reassociatedAccountNameFormatter = function (item) {
	        if (this.accountsReassociatedRequest.data().OriginalAccountsCount > 1)
		        return item.ExtendedName;
	        else
		        return item.SimpleName;
        }.bind(this);

        this.accountsHsa = ko.observable([]);
        this.selectedHistoricalAccount = ko.observable(null);
        this.aggregatedStatus.isLoading.subscribe(function(isLoading) {
            if (isLoading === false) {
                this.accountsHsa($.cdh.accounts.listHsa());
                this.selectedHistoricalAccount(this.accountsHsa()[0].AccountId);
            }
        }, this);

        this.displayHistoricalData = function() {
            this.historical(true);

            $.cdh.helper.titlePage('Historical Tax Forms');

            var accountId = this.selectedHistoricalAccount();
            this.itemsReassociatedRequest.load({ id: accountId });

            this.selectedHistoricalAccount.subscribe(function(accountId) {
	            this.itemsReassociatedRequest.load({ id: accountId });
            }, this);

        }.bind(this);

        this.deliveryOptionName = ko.pureComputed(function() {
	        if (this.deliveryOptionRequest.hasData() === false)
                return null;

            var option = this.deliveryOption();
            if (option == $.cdh.enums.taxFormsPaperOrElectronic.electronic)
                return 'Electronic Only';
            else
                return 'Paper';
        }, this);

        this.editPreferences = function() {
	        this.currentStepModel(new DeliveryPreferenceStepModel(this));
        }.bind(this);

        this.isMainView = ko.pureComputed(function() {
	        return this.currentStepModel() === null;
        }, this);

        this.isDeliveryPreferenceView = ko.pureComputed(function() {
	        return this.currentStepModel() !== null && this.currentStepModel().isDeliveryPreferenceStep;
        }, this);

        if (this.streamline == 'true') {
	        this.currentStepModel(new DeliveryPreferenceStepModel(this));
        }
    }

    function DeliveryPreferenceStepModel(model) {
        this.isDeliveryPreferenceStep = true;
        this.model = model;
        this.title = 'Tax Forms Delivery Preference';
    }
    
})(jQuery, ko);