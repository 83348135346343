import template from "./scheduled-payments-details.html";
import {PaymentsService} from "../../Http/Payments/PaymentsService";

ko.components.register('scheduled-payments-details', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new ScheduledPayments(params, widgetInfo);
        }
    }
});

class ScheduledPayments {
    constructor(params, widgetInfo) {
        this.widgetInfo = widgetInfo;

        this.paymentsService = new PaymentsService();
        this.template = new $.cdh.services.templateExtractor(params.template);

        this.isVisible = ko.observable(false);
        this.title = ko.observable();
        this.payments = ko.observableArray();

        this.futurePaymentType = {
            hsaPayments: $.cdh.enums.futurePaymentsTypeFilter.hsapayments,
            claimPayments: $.cdh.enums.futurePaymentsTypeFilter.claimPayments
        };

        this.filterDisabled = ko.pureComputed(() => this.paymentsService.loading());

        this.selectedTypes = ko.observableArray([this.futurePaymentType.hsaPayments, this.futurePaymentType.claimPayments]);

        this.filterSetters = [
            {
                text: $.cdh.languageConstant.getConstant("filter.all"), setter: () => {
                    this.selectedTypes([this.futurePaymentType.hsaPayments, this.futurePaymentType.claimPayments]);
                    this.load();
                }
            },
            {
                text: $.cdh.languageConstant.getConstant("field.hsa-payments"), setter: () => {
                    this.selectedTypes([this.futurePaymentType.hsaPayments]);
                    this.load();
                }
            },
            {
                text: $.cdh.languageConstant.getConstant("field.claim-payment"), setter: () => {
                    this.selectedTypes([this.futurePaymentType.claimPayments]);
                    this.load();
                }
            }
        ];


        this.refreshCallback = () => this.load();
        
        this.paymentRemoved = () => this.load();

        this.load();

        widgetInfo.updateHeight();
    }

    setTitle() {
        let titleLabel = $.cdh.widgetLabels.scheduledPayments.title;
        let title = this.template.getLabel(titleLabel.name, titleLabel.default);
        this.title(title);
    }

    clickTypeFilter() {
        this.load();
        return true;
    }

    async load() {
        let type = $.cdh.enums.futurePaymentsTypeFilter.all;
        if (this.selectedTypes().length === 1) {
            type = this.selectedTypes()[0];
        } else if(this.selectedTypes().length === 0){
            type = null;
        }

        let result = await this.paymentsService.getFutureRecurringPaymentsAsync(type);
        let payments = result.data().Payments;

        this.setTitle();
        this.isVisible(true);
        this.payments(payments);
        this.widgetInfo.updateHeight();
    }
}