import template from "./custom-taxform-menu-item.html";
import { AccountsService } from "../Http/Accounts/AccountsService";

ko.components.register('custom-taxform-menu-item', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo) {
            return new CustomTaxFormMenuItem(params);
        }
    }
});

function CustomTaxFormMenuItem(params) {
    // Invoke accounts service only if user is authenticated
    if ($.cdh.user.authenticated) {
        let taxformsUrl = `/Page/${params.pageName}`;
        this.accountId = ko.observable();
        this.menuText = ko.observable(params.name);
        this.link = ko.observable(taxformsUrl);
        this.accountService = new AccountsService();
        this.accountService.getListHsaAsync($.cdh.enums.planYear.all).then((accounts) => {
            // Construct the URL for TaxForms 
            if (accounts && accounts.length > 0 && accounts.filter(ac => ac.IsHsa == true && ac.IsVeba == false).length > 0) {
                let sortedHSAAccountList = accounts.filter(ac => ac.IsHsa == true && ac.IsVeba == false).sort((a, b) => a.Priority - b.Priority);
                this.link(`${taxformsUrl}` + "?accountId=" + sortedHSAAccountList[0].AccountId);
            }

            // TaxForm menu item should not be visible when it is configured in CMS but user don't have HSA account
            // TaxForm menu item should not be visible for VEBA accounts
            else if (accounts && (accounts.length == 0 || accounts.filter(ac => ac.IsHsa == true && ac.IsVeba == false).length == 0)) {
                $("#custom-taxforms-menu").parent().parent().remove();
            }
        });
    }
}