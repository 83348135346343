import template from "./balance-due.html"

(function ($, ko) {
    ko.components.register('balance-due', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new BalanceDue(params, widgetInfo.updateHeight, widgetInfo.remove);
            }
        }
    });

    function BalanceDue(params, updateHeight, removeWidget) {
        const self = this;

        self.BalanceDueLabel = {
            lblPayNow: $.cdh.languageConstant.getConstant('button.repay-now'),
            lblViewStatus: $.cdh.languageConstant.getConstant('button.view-status'),
            lblPayViewStatus: $.cdh.languageConstant.getConstant('button.pay-view-status')
        };

        self.balanceAlertRequest = new $.cdh.get.repayment.balanceAlert();
        self.template = new $.cdh.services.templateExtractor(params.template);

        self.title = ko.observable();
        self.balanceDue = ko.observable();
        self.message = ko.observable();
        self.isVisible = ko.observable(false);
        self.balanceDuebuttonText = ko.observable(false);

        self.isSetMessage = ko.pureComputed(function () {
            return $.cdh.helper.isValueSet(self.message());
        });

        function setTitle() {
            let titleLabel = $.cdh.widgetLabels.balanceDue.title;
            let title = self.template.getLabel(titleLabel.name, titleLabel.default);
            self.title(title);
        }

        self.balanceAlertRequest.load().done(function (data) {
            if (data.OnlineRepaymentAllowed && (data.BalanceDue > 0 || data.TransactionsPendingProcessing)) {
                setTitle();
                self.isVisible(true);
                self.balanceDue(data.BalanceDue);
                self.message(data.Message);
                updateHeight();
            } else {
                removeWidget();
            }

            if (data.BalanceDue > 0 && !data.TransactionsPendingProcessing)
                self.balanceDuebuttonText(self.BalanceDueLabel.lblPayNow);
            else if (data.BalanceDue <= 0 && data.TransactionsPendingProcessing)
                self.balanceDuebuttonText(self.BalanceDueLabel.lblViewStatus);
            else if (data.BalanceDue > 0 && data.TransactionsPendingProcessing)
                self.balanceDuebuttonText(self.BalanceDueLabel.lblPayViewStatus);
        });
    }

})(jQuery, ko);