import template from "./account-activity-item-details.html"

(function ($, ko) {
    ko.components.register('account-activity-item-details', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityItem(params, updateContainerHeight(componentInfo.element));
            }
        }  
    });

    function AccountActivityItem(params, updateHeight) {
        const self = this;
        let model = params.model;
        let isValueSet = $.cdh.helper.isValueSet;
        
        self.model = model;
        self.MultipleMarking = params.MultipleMarking;
        self.accountsLoaded = params.accountsLoaded;
        self.reloadListCallback = params.reloadListCallback;
        self.color = params.color;
        self.isMobileDetails = params.isMobileDetails;
        self.close = params.closeFunc || function () {};

        self.isPaymentDetailsAllowed = isValueSet(model.ReimbursementDetails) && model.ReimbursementDetails.length > 0;
        
        self.isFxClaim = model.IsFxClaim;
        self.isAdjudicationExists = $.cdh.helper.isValueSet(model.ClaimAdjudicationDetails);

        self.isTcClaim = model.IsTcClaim;
        self.isEobPaymentExists = $.cdh.helper.isValueSet(model.ExpensesDetails) && model.ExpensesDetails.length > 0;
        self.hasReceipts = model.HasReceipts;
        self.allowFaxCover = model.AllowFaxCover;
        
        self.isCardTransaction = model.IsCardTransaction;
        self.docusignUrl = model.DocusignUrl;
        self.balanceDue = model.BalanceDue;
        self.isMemberClaim = model.IsMemberClaim;
        self.isManualClaim = model.IsManualClaim;
        self.isRefund = model.IsRefund;
        self.isExpense = model.IsExpense;
        self.expenseEditable = model.ExpenseEditable;
        self.showPayNow = params.showPayNow;

        self.canUploadReceipt = model.CanUploadReceipt;
        self.canRequestReimbursement = $.cdh.employee.canRequestReimbursement() && model.CanRequestReimbursement;
        self.canMarkAsPaid = model.CanMarkAsPaid;
        
        self.isHsa = model.IsHsa;
        self.isHsaTransaction = model.IsHsaTransaction;
        self.isHsaBillPay = model.IsHsaBillPay;
        self.isHsaDeposit = model.IsHsaDeposit;
        self.isInterest = model.IsInterest;
        self.isEarnings = model.IsEarnings;
        self.isForfeiture = model.IsForfeiture;
        self.depositDetails = model.DepositDetails;
        self.payNowVisible = ko.observable(false);
        self.statuscode = model.StatusCode;
        self.type = model.TypeCode;
        self.accountType = model.AcctTypeClass;
        self.clickMarkasIneligible = ko.observable(false);
        if ($.cdh.employee.hasAccessToMarkAsInelgible() && this.type == 2) {
            if (this.statuscode == 11 || this.statuscode == 12)
            {
                self.clickMarkasIneligible(true);
            }
        }
        self.ReceiptsCount = ko.pureComputed(function() {
           return model.Receipts().length; 
        });

        if (self.balanceDue) {
            self.payNowVisible(!(self.accountType === 3 || self.accountType === 6));
        } else {
            self.payNowVisible(false);
        }

        self.handleimpersonation = function (data, event) {
            event.preventDefault();
            var impersonationMessage = document.getElementById('impersonationError');
            if (impersonationMessage == null) {
                var alertmessage = `<div id="impersonationError" class="popup-form_error-title" role="alert"><span aria-live="assertive">${$.cdh.languageConstant.getConstant('messages.user-impersonated-error')}</span></div>`;
                $(".main-content").prepend(alertmessage);
            }
            window.scrollTo(0, 0);
            return true;
        };       
    }

})(jQuery, ko);